<template>
  <div class="archived-rent-detail">
    <div class="container fluid">
      <div class="d-flex align-items-center">
        <fd-button class="circle flat main" @click="toList">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h1 class="my-4 mx-2">Archived Rent</h1>
      </div>
      <div class="card p-3 mb-2">
        <div class="row">
          <display-data
            class="col-12 md-col-6"
            label="ID"
            :content="archivedRent.id"
          ></display-data>
          <div class="col-12 md-col-6 fg-grey-600 text-right mb-3">
            <div>
              Created:
              {{ $dayjs(archivedRent.createdAt).format("DD-MM-YYYY h:mm A") }}
            </div>
            <div>
              Last Updated:
              {{ $dayjs(archivedRent.updatedAt).format("DD-MM-YYYY h:mm A") }}
            </div>
          </div>
        </div>

        <fd-button class="bordered main" @click="toEdit">
          <i class="fas fa-pen mr-1"></i> Edit
        </fd-button>
        <fd-button
          class="bordered main ml-2"
          @click="downloadTA(archivedRent.id)"
        >
          <i class="fas fa-file-export mr-1"></i> Download TA
        </fd-button>
      </div>

      <!-- Landlords & Tenants -->
      <div class="landlord-tenant-grid">
        <!-- Landlords -->
        <div class="card p-3 mb-2">
          <h3 class="mb-4">Landlords</h3>
          <users-detail
            v-if="!_.isEmpty(archivedRent.tenancyAgreement.landLordDetail)"
            class=""
            :taUser="archivedRent.tenancyAgreement.landLordDetail"
            landlord
          ></users-detail>
        </div>

        <!-- Tenants -->
        <div class="card p-3 mb-2">
          <h3 class="mb-4">Tenants</h3>
          <users-detail
            v-if="!_.isEmpty(archivedRent.tenancyAgreement.tenantDetail)"
            :taUser="archivedRent.tenancyAgreement.tenantDetail"
          ></users-detail>
        </div>
      </div>

      <!-- Tenancy Agreement -->
      <general-detail
        class="card p-3 mb-2"
        :tenancyAgreement="archivedRent.tenancyAgreement"
      ></general-detail>
    </div>
  </div>
</template>

<script>
import {
  getArchiveRent,
  downloadTenancyAgreement
} from "@/modules/Rent/api/archiveRent";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    GeneralDetail: () =>
      import("@/modules/Rent/components/TAForm/GeneralDetail"),
    UsersDetail: () => import("@/modules/Rent/components/TAForm/UsersDetail")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      archivedRent: {
        id: "",
        otrForm: [],
        taForm: [],
        property: {},
        tenancyAgreement: {
          id: "",
          agreementDate: "",
          terms: "",
          commencementDate: "",
          dateOfExpiry: "",
          monthlyRent: "",
          securityDeposit: "",
          utilitiesDeposit: "",
          otherDeposit: "",
          remark: "",
          rentFreePeriodFrom: "",
          rentFreePeriodTo: "",
          taxRate: "",
          taxAmount: "",
          purpose: "",
          furtherTerm: "",
          rentalFurtherTerm: "",
          offerToLeaseType: "",
          offerToLeaseDocuments: "",
          specialConditions: "",
          createdAt: "",
          updatedAt: "",
          landLordDetail: {},
          tenantDetail: {}
        }
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getArchivedRent();
    },
    toList() {
      this.$router.push({ name: "ArchivePropertyRentList" });
    },
    toEdit() {
      this.$router.push({ name: "ArchivePropertyRentEdit" });
    },
    async getArchivedRent() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await getArchiveRent(this.$route.params.id);
        this.archivedRent = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get archived rent detail. Please try again later."
        });
      }
    },
    async downloadTA(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await downloadTenancyAgreement(id);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to download tenancy agreement. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.archived-rent-detail {
  .landlord-tenant-grid {
    display: grid;
    gap: 0 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media #{$breakpoint-down-md} {
    .landlord-tenant-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
